import * as React from 'react'
import Text from './Text'

const ContentRow = ({title, text}) => {
    return (
        <div class="row content">
            <div class="six columns">
                <h3 class="small-title">{title}</h3>
            </div>
            <div class="six columns">
                <div class="text">
                    <Text text={text}></Text>
                </div>
            </div>
        </div>
    )
}

export default ContentRow


import "../../../../src/styles/work.css";
import "../../../../src/styles/home.css";
import dheader from "../../../../src/images/Daydreams/Header.png";
import dfooter from "../../../../src/images/Daydreams/Footer.png";
import dr3 from "../../../../src/images/Daydreams/R2.png";
import dr1 from "../../../../src/images/Daydreams/R1.png";
import dr2 from "../../../../src/images/Daydreams/R3.png";
import ContentRow from "../../../../src/components/ContentRow.js";
import * as React from 'react';
export default {
  dheader,
  dfooter,
  dr3,
  dr1,
  dr2,
  ContentRow,
  React
};
import "../../../../src/styles/work.css";
import "../../../../src/styles/home.css";
import ContentRow from "../../../../src/components/ContentRow.js";
import { IconContext } from 'react-icons';
import { FaWalking, FaBolt, FaRocket } from 'react-icons/fa';
import Text from "../../../../src/components/Text.js";
import Nheader from "../../../../src/images/Nike/Header.png";
import Nmoodboard from "../../../../src/images/Nike/Moodboard.png";
import NType from "../../../../src/images/Nike/Typography.png";
import NSketches from "../../../../src/images/Nike/Sketches.png";
import NUI1 from "../../../../src/images/Nike/UI1.png";
import NFinal from "../../../../src/images/Nike/Final.png";
import * as React from 'react';
export default {
  ContentRow,
  IconContext,
  FaWalking,
  FaBolt,
  FaRocket,
  Text,
  Nheader,
  Nmoodboard,
  NType,
  NSketches,
  NUI1,
  NFinal,
  React
};
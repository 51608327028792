import "../../../../src/styles/work.css";
import "../../../../src/styles/home.css";
import Fheader from "../../../../src/images/Faith/Header.png";
import mr from "../../../../src/images/Faith/MR@3x.png";
import flow from "../../../../src/images/Faith/Flow.png";
import fr3 from "../../../../src/images/Faith/R1.png";
import fr5 from "../../../../src/images/Faith/R2.png";
import ContentRow from "../../../../src/components/ContentRow.js";
import * as React from 'react';
export default {
  Fheader,
  mr,
  flow,
  fr3,
  fr5,
  ContentRow,
  React
};